import ArrowRightPlaceholder from "~/public/assets/images/placeholders/ArrowRightPlaceholder.svg?url";
import Balloon from "~/public/assets/images/placeholders/Balloon.svg?url";
import Bin from "~/public/assets/images/placeholders/Bin.svg?url";
import Cleo from "~/public/assets/images/placeholders/Cleo.svg?url";
import EmptyItems from "~/public/assets/images/placeholders/EmptyItems.svg?url";
import EmptyList from "~/public/assets/images/placeholders/EmptyList.svg?url";
import EmptyProperties from "~/public/assets/images/placeholders/EmptyProperties.svg?url";
import Gift from "~/public/assets/images/placeholders/Gift.svg?url";
import Lock from "~/public/assets/images/placeholders/Lock.svg?url";
import ProcessingPdf from "~/public/assets/images/placeholders/ProcessingPdf.svg?url";
import ProcessingVideo from "~/public/assets/images/placeholders/ProcessingVideo.svg?url";
import ShieldWithCheck from "~/public/assets/images/placeholders/ShieldWithCheck.svg?url";
import UnknownError from "~/public/assets/images/placeholders/UnknownError.svg?url";
import UserOnHexagon from "~/public/assets/images/placeholders/UserOnHexagon.svg?url";
import UserOnShield from "~/public/assets/images/placeholders/UserOnShield.svg?url";
import Warning from "~/public/assets/images/placeholders/Warning.svg?url";

export const IMAGE_VARIANTS_MAP = {
  cleo: Cleo,
  gift: Gift,
  emptyList: EmptyList,
  balloon: Balloon,
  unknownError: UnknownError,
  userOnShield: UserOnShield,
  userOnHexagon: UserOnHexagon,
  shieldWithCheck: ShieldWithCheck,
  lock: Lock,
  warning: Warning,
  bin: Bin,
  arrowRightPlaceholder: ArrowRightPlaceholder,
  emptyItems: EmptyItems,
  emptyProperties: EmptyProperties,
  processingPdf: ProcessingPdf,
  processingVideo: ProcessingVideo
};
