import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import useTranslation from "next-translate/useTranslation";

export type AuthContentFooterProps = {
  textTranslationKey: string;
  link: {
    href: string;
    labelTranslationKey: string;
  };
};

const AuthContentFooter = ({
  textTranslationKey,
  link
}: AuthContentFooterProps): JSX.Element => {
  const { t } = useTranslation("auth");

  return (
    <Footer>
      <Typography color="text.secondary" variant="titleTertiary">
        {t(textTranslationKey)}
      </Typography>
      <Button
        href={link.href}
        variant="text"
        size="extraLarge"
        color="brandPurple"
      >
        {t(link.labelTranslationKey)}
      </Button>
    </Footer>
  );
};

export default AuthContentFooter;

const Footer = styled.footer`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  justify-content: center;
`;
