import EarthImage from "~/public/assets/images/statements/Earth.png";
import EarthImage2x from "~/public/assets/images/statements/Earth@2x.png";
import PlasticRemovalImage from "~/public/assets/images/statements/PlasticRemoval.png";
import PlasticRemovalImage2x from "~/public/assets/images/statements/PlasticRemoval@2x.png";
import TreePlantingImage from "~/public/assets/images/statements/TreePlanting.png";
import TreePlantingImage2x from "~/public/assets/images/statements/TreePlanting@2x.png";
import { BRAND_COLOR_SET } from "~/theme/createPalette";
import { buildSrcSetForRetina } from "~/utils/images/buildSrcSetForRetina";

import { AuthStatement, AuthStatementModel } from "./declarations";

export const AUTH_STATEMENTS: AuthStatementModel[] = [
  {
    type: AuthStatement.initial,
    backgroundColor: BRAND_COLOR_SET.brandGreen.main,
    highlightedColor: BRAND_COLOR_SET.brandPurple.main,
    activeSelectorColor: "brandPurple.main",
    image: buildSrcSetForRetina(EarthImage.src, EarthImage2x.src),
    titleTranslationKey: "auth:statement-initial_title",
    textTranslationKey: "auth:statement-initial_text"
  },
  {
    type: AuthStatement.plasticRemoval,
    backgroundColor: BRAND_COLOR_SET.brandBlue.main,
    highlightedColor: BRAND_COLOR_SET.brandGreen.main,
    activeSelectorColor: "brandPurple.main",
    image: buildSrcSetForRetina(
      PlasticRemovalImage.src,
      PlasticRemovalImage2x.src
    ),
    titleTranslationKey: "auth:statement-plastic-removal_title",
    textTranslationKey: "auth:statement-plastic-removal_text"
  },
  {
    type: AuthStatement.treePlanting,
    backgroundColor: BRAND_COLOR_SET.brandPurple.main,
    highlightedColor: BRAND_COLOR_SET.brandYellow.main,
    activeSelectorColor: "brandYellow.main",
    image: buildSrcSetForRetina(TreePlantingImage.src, TreePlantingImage2x.src),
    titleTranslationKey: "auth:statement-tree-planting_title",
    textTranslationKey: "auth:statement-tree-planting_text"
  }
];
