import styled from "@emotion/styled";
import CircularProgress from "@mui/material/CircularProgress";
import { inputClasses } from "@mui/material/Input";
import { forwardRef, useState } from "react";

import Icon from "~/components/core/Icon";
import IconButton from "~/components/core/IconButton";
import TextField, { TextFieldProps } from "~/components/core/TextField";

export type PasswordInputProps = Omit<TextFieldProps, "variant"> & {
  error?: boolean;
  readOnly?: boolean;
  label?: string;
  placeholder?: string;
  className?: string;
  loading?: boolean;
};

const PasswordField = forwardRef<HTMLDivElement, PasswordInputProps>(
  (
    {
      className,
      label,
      placeholder,
      error,
      inputProps,
      readOnly = false,
      loading = false,
      ...rest
    }: PasswordInputProps,
    ref
  ) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const handleTogglePasswordVisibility = (): void => {
      setShowPassword(!showPassword);
    };

    return (
      <StyledTextField
        fullWidth
        label={label}
        placeholder={placeholder}
        type={showPassword ? "text" : "password"}
        error={error}
        inputRef={ref}
        className={className}
        inputProps={{ ...inputProps, readOnly }}
        startAdornment={
          <Icon
            variant="lock"
            color="brandGreen.main"
            colorSecondary="background.primary"
          />
        }
        endAdornment={
          loading ? (
            <CircularProgress size={20} />
          ) : (
            <StyledButton
              tabIndex={-1}
              onClick={handleTogglePasswordVisibility}
              icon={showPassword ? "eye" : "eyeClosed"}
              iconColor="brandGreen.main"
              iconColorSecondary="background.primary"
            />
          )
        }
        {...rest}
      />
    );
  }
);

const StyledTextField = styled(TextField)`
  .${inputClasses.input}::placeholder {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.2px;
    line-height: 20px;
  }
`;

const StyledButton = styled(IconButton)`
  height: 20px;
  width: 20px;
`;

export default PasswordField;
