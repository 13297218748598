import { IconColor } from "~/components/core/Icon/declarations";

export enum AuthStatement {
  initial = "initial",
  treePlanting = "treePlanting",
  plasticRemoval = "plasticRemoval"
}

export type AuthStatementModel = {
  type: AuthStatement;
  backgroundColor: string;
  highlightedColor: string;
  activeSelectorColor: IconColor;
  image: string;
  titleTranslationKey: string;
  textTranslationKey: string;
  textTranslationOptions?: Record<string, string>;
};
