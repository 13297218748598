import { ChangeEvent, FocusEventHandler, ReactNode } from "react";

import Icon from "~/components/core/Icon";
import { IconVariant } from "~/components/core/Icon/declarations";
import TextField, { TextFieldProps } from "~/components/core/TextField";

type Props = {
  value: string;
  color?: TextFieldProps["color"];
  label?: ReactNode;
  name?: string;
  placeholder?: string;
  error?: boolean;
  loading?: boolean;
  type?: "email" | "text";
  iconVariant?: IconVariant;
  readOnly?: boolean;
  className?: string;
  onChange(event: ChangeEvent<HTMLInputElement>): void;
  onBlur?: FocusEventHandler;
  onClickClear?: () => void;
};

const EmailField = ({
  value,
  name,
  placeholder,
  label,
  error,
  readOnly,
  onChange,
  onBlur,
  onClickClear,
  color = "secondary"
}: Props): JSX.Element => (
  <TextField
    color={color}
    name={name}
    label={label}
    value={value}
    placeholder={placeholder}
    error={error}
    onClear={onClickClear}
    onChange={onChange}
    onBlur={onBlur}
    inputProps={{
      readOnly
    }}
    startAdornment={
      <Icon
        size={20}
        variant="mail"
        color="brandGreen.main"
        colorSecondary="background.primary"
      />
    }
    fullWidth
    withErrorIndicator
  />
);

export default EmailField;
