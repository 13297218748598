import styled from "@emotion/styled";
import { forwardRef, ReactNode } from "react";

import AuthContentCaption, { AuthCaptionProps } from "./AuthContentCaption";
import AuthContentFooter, { AuthContentFooterProps } from "./AuthContentFooter";
import AuthContentHeader from "./AuthContentHeader";

export type AuthContentProps = {
  footerProps?: AuthContentFooterProps;
  className?: string;
  smallVerticalPaddings?: boolean;
  children: ReactNode;
  onBack?: () => void;
} & AuthCaptionProps;

const AuthContent = forwardRef<HTMLDivElement, AuthContentProps>(
  (
    {
      className,
      children,
      smallVerticalPaddings,
      onBack,
      footerProps,
      ...captionProps
    },
    ref
  ): JSX.Element => (
    <Wrapper className={className} ref={ref}>
      <StyledHeader onBack={onBack} />
      <ContentWrapper smallVerticalPaddings={smallVerticalPaddings}>
        <Content>
          <AuthContentCaption {...captionProps} />
          {children}
        </Content>
      </ContentWrapper>
      {footerProps && <AuthContentFooter {...footerProps} />}
    </Wrapper>
  )
);

export default AuthContent;

const Wrapper = styled.section`
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
  margin: 0 auto;
  max-width: 752px;
  overflow: hidden;
  padding: ${({ theme }) => theme.spacing(10, 0, 10.5)};

  ${({ theme }) => theme.breakpoints.down("lg")} {
    padding: ${({ theme }) => theme.spacing(6, 10, 6.5)};
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: ${({ theme }) => theme.spacing(10, 10, 10.5)};
  }

  ${({ theme }) => theme.breakpoints.down("xs")} {
    padding: ${({ theme }) => theme.spacing(4, 4, 6.5)};
  }
`;

const StyledHeader = styled(AuthContentHeader)`
  justify-self: flex-start;
`;

const ContentWrapper = styled.div<{ smallVerticalPaddings?: boolean }>`
  border: 1px solid ${({ theme }) => theme.palette.background.senary};
  border-radius: 24px;
  margin: auto 0;
  padding: ${({ theme, smallVerticalPaddings }) =>
    theme.spacing(smallVerticalPaddings ? 12 : 18, 0)};
  width: 100%;

  ${({ theme }) => theme.breakpoints.down("xs")} {
    border: none;
  }
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 344px;
  width: 100%;
`;
