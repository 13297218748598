import styled from "@emotion/styled";

import Icon from "~/components/core/Icon";
import { IconColor } from "~/components/core/Icon/declarations";

type Props = {
  active: boolean;
  activeColor: IconColor;
  index: number;
  onClick: (index: number) => void;
};

const AuthLayoutStatementCarouselSlideSelector = ({
  active,
  activeColor,
  index,
  onClick
}: Props): JSX.Element => {
  if (active) {
    return <ActiveSelector variant="starFourPointed" color={activeColor} />;
  }

  const handleClick = (): void => onClick(index);

  return <InactiveSelector onClick={handleClick} />;
};

export default AuthLayoutStatementCarouselSlideSelector;

const InactiveSelector = styled.button`
  background-color: ${({ theme }) => theme.palette.background.octonary};
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 6px;
  padding: 0;
  width: 6px;
`;

const ActiveSelector = styled(Icon)`
  height: 17px;
  width: 17px;
`;
