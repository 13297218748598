import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import { useEffect, useRef, useState } from "react";

import AuthLayoutStatementCarouselSlideSelector from "./AuthLayoutStatementCarouselSlideSelector";
import { AUTH_STATEMENTS } from "./constants";

const AUTO_CHANGE_INDEX_INTERVAL = 6000;
const STATEMENTS_COUNT = AUTH_STATEMENTS.length;

const getNewCircularIndex = (
  indexDelta: number,
  currentIndex: number
): number => {
  const statementsCount = STATEMENTS_COUNT;
  const newIndexWithDelta = currentIndex + indexDelta;
  let newIndex = newIndexWithDelta;

  if (newIndexWithDelta > statementsCount - 1) {
    newIndex = 0;
  }

  if (newIndexWithDelta < 0) {
    newIndex = statementsCount - 1;
  }

  return newIndex;
};

const AuthLayoutStatementCarousel = (): JSX.Element => {
  const { t } = useTranslation("auth");
  const [statementIndex, setStatementIndex] = useState(0);
  const statement = AUTH_STATEMENTS[statementIndex];
  const refInterval = useRef<null | number>(null);

  const handleChangeIndex = (index: number): void => setStatementIndex(index);

  useEffect(() => {
    refInterval.current = window.setTimeout(
      () => setStatementIndex(getNewCircularIndex(1, statementIndex)),
      AUTO_CHANGE_INDEX_INTERVAL
    );

    return () => {
      if (refInterval.current) {
        window.clearTimeout(refInterval.current);
      }
    };
  }, [statementIndex]);

  return (
    <Wrapper backgroundColor={statement.backgroundColor}>
      <Image srcSet={statement.image} />
      <div>
        <Title textAlign="center" color="text.quaternary">
          <Trans
            i18nKey={statement.titleTranslationKey}
            components={{
              highlighted: <Highlighted color={statement.highlightedColor} />
            }}
          />
        </Title>
        <Text
          textAlign="center"
          variant="paragraphTertiary"
          color="text.quaternary"
        >
          {t(statement.textTranslationKey, { appName: t("common:app-name") })}
        </Text>

        <NavigationWrapper>
          {new Array(STATEMENTS_COUNT).fill(0).map((_, index) => (
            <AuthLayoutStatementCarouselSlideSelector
              key={index}
              index={index}
              active={index === statementIndex}
              activeColor={statement.activeSelectorColor}
              onClick={handleChangeIndex}
            />
          ))}
        </NavigationWrapper>
      </div>
    </Wrapper>
  );
};

export default AuthLayoutStatementCarousel;

const Wrapper = styled.div<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  position: fixed;
  transition: background-color 0.5s ease-in-out;
`;

const Highlighted = styled.span<{ color: string }>`
  color: ${({ color }) => color};
`;

const Image = styled.img`
  margin: ${({ theme }) => theme.spacing(10)};
  max-height: 60%;
  max-width: 100%;
  object-fit: contain;
`;

const Title = styled(Typography)``;
Title.defaultProps = {
  variant: "h3"
};

const Text = styled(Typography)`
  box-sizing: content-box;
  display: block;
  min-height: 141px;
  padding: ${({ theme }) => theme.spacing(5.25, 8, 0)};
`;

const NavigationWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.spacing(3)};
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing(6)};
`;
