import styled from "@emotion/styled";
import InputAdornment from "@mui/material/InputAdornment";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import MuiTextField, {
  BaseTextFieldProps as MuiBaseTextFieldProps,
  OutlinedTextFieldProps as MuiOutlinedTextFieldProps
} from "@mui/material/TextField";
import get from "lodash.get";
import {
  FocusEvent,
  forwardRef,
  ReactNode,
  useCallback,
  useMemo,
  useState
} from "react";

import IconButton from "~/components/core/IconButton";
import { FakeClearEventHandler } from "~/declarations/syntheticEvents";
import { AllBrandColorsFromPalette } from "~/theme/colorsFromPalette";

import Icon from "../Icon";

interface BaseTextFieldProps extends Omit<MuiBaseTextFieldProps, "value"> {
  activeColor?: AllBrandColorsFromPalette;
  value: string;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
}

interface OutlinedTextFieldProps extends BaseTextFieldProps {
  withErrorIndicator?: boolean;
  InputProps?: MuiOutlinedTextFieldProps["InputProps"];
  onChange?: MuiOutlinedTextFieldProps["onChange"];
  onClear?: FakeClearEventHandler;
}

export type TextFieldProps = OutlinedTextFieldProps;

const TextField = forwardRef<HTMLDivElement, TextFieldProps>(
  (
    {
      value,
      startAdornment,
      endAdornment,
      label,
      error,
      withErrorIndicator,
      InputProps,
      onFocus,
      onBlur,
      onClear,
      activeColor = "brandBlue.main",
      autoComplete = "off",
      ...props
    },
    ref
  ): JSX.Element => {
    const [focused, setFocused] = useState<boolean>(false);

    const handleClickClearButton = useCallback(() => {
      onClear?.({
        currentTarget: {
          name: props.name ?? ""
        }
      });
    }, [onClear, props.name]);

    const handleFocus = useCallback(
      (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFocused(true);
        onFocus?.(event);
      },
      [onFocus]
    );

    const handleBlur = useCallback(
      (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFocused(false);
        onBlur?.(event);
      },
      [onBlur]
    );

    const StartAdornment = useMemo(() => {
      if (startAdornment) {
        return (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        );
      }
    }, [startAdornment]);

    const EndAdornment = useMemo(() => {
      let clearElement = null;
      let errorIndicator = null;
      const readOnly = props?.inputProps?.readOnly ?? false;

      if ((focused || error) && value && onClear && !readOnly) {
        clearElement = (
          <ClearButton
            tabIndex={-1}
            onMouseDown={handleClickClearButton}
            color="transparent"
            iconColor="transparent.inverse.48"
          />
        );
      }

      if (error && withErrorIndicator) {
        errorIndicator = (
          <Icon size={20} variant="info" color="additional.red.normal" />
        );
      }

      if (endAdornment || clearElement || errorIndicator) {
        return (
          <InputAdornment position="end">
            {clearElement}
            {errorIndicator}
            {endAdornment}
          </InputAdornment>
        );
      }
    }, [
      endAdornment,
      error,
      focused,
      handleClickClearButton,
      onClear,
      props?.inputProps?.readOnly,
      value,
      withErrorIndicator
    ]);

    return (
      <StyledTextField
        ref={ref}
        {...props}
        error={error}
        activeColor={activeColor}
        value={value}
        variant="outlined"
        label={label}
        autoComplete={autoComplete}
        onFocus={handleFocus}
        onBlur={handleBlur}
        InputLabelProps={{
          shrink: true,
          ...props.InputLabelProps
        }}
        InputProps={{
          ...InputProps,
          startAdornment: StartAdornment,
          endAdornment: EndAdornment
        }}
      />
    );
  }
);

export default TextField;

const StyledTextField = styled(MuiTextField)<{
  activeColor: AllBrandColorsFromPalette;
}>`
  &.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline} {
    border: 2px solid
      ${({ theme, activeColor }) => get(theme.palette, activeColor)};
  }

  .${outlinedInputClasses.input} {
    caret-color: ${({ theme, activeColor }) => get(theme.palette, activeColor)};
  }
`;

const ClearButton = styled(IconButton)`
  height: 20px;
  margin-right: ${({ theme }) => theme.spacing(1)};
  width: 20px;
`;
ClearButton.defaultProps = {
  variant: "rounded",
  icon: "clear",
  size: 32
};
