import styled from "@emotion/styled";
import { useMemo } from "react";

import { IMAGE_VARIANTS_MAP } from "./constants";
import { PlaceholderImageVariant } from "./declarations";

type Props = {
  variant: PlaceholderImageVariant;
  alt?: string;
  className?: string;
};

const PlaceholderImage = ({ variant, alt, className }: Props): JSX.Element => {
  const placeholderImageSrc = useMemo(
    () => IMAGE_VARIANTS_MAP[variant],
    [variant]
  );

  return <Image className={className} alt={alt} src={placeholderImageSrc} />;
};

export default PlaceholderImage;

export const Image = styled.img`
  height: 72px;
  width: 72px;
`;
