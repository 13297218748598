import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTranslation from "next-translate/useTranslation";

import Logo from "~/components/common/Logo";
import IconButton from "~/components/core/IconButton";

type Props = {
  className?: string;
  onBack?: () => void;
};

const AuthContentHeader = ({ className, onBack }: Props): JSX.Element => {
  const { t } = useTranslation("auth");
  const theme = useTheme();
  const betweenMDandSM = useMediaQuery(theme.breakpoints.between("md", "sm"));

  if (onBack) {
    return (
      <BackButtonWrapper className={className}>
        <IconButton
          variant="rounded"
          color="brandGreen.light"
          icon="chevronLeft"
          onClick={onBack}
        />
        <Typography variant="buttonQuaternary">{t("common:back")}</Typography>
      </BackButtonWrapper>
    );
  }

  return <Logo className={className} size={betweenMDandSM ? "l" : "xl"} />;
};

export default AuthContentHeader;

const BackButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.spacing(3)};
  margin-right: auto;
`;
