import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";

import { PlaceholderImageVariant } from "~/components/common/PlaceholderImage/declarations";
import PlaceholderImage from "~/components/common/PlaceholderImage/PlaceholderImage";
import useDownBreakpoint from "~/utils/useDownBreakpoint";

export type AuthCaptionProps = {
  caption: string;
  subCaption: string;
  captionVariantUpFromMD?: "h3" | "h4";
  captionVariantDownMD?: "h3" | "h4";
  topImageVariant?: PlaceholderImageVariant;
  captionEmojiImage?: string;
};

const AuthCaption = ({
  caption,
  subCaption,
  captionEmojiImage,
  topImageVariant,
  captionVariantDownMD = "h4",
  captionVariantUpFromMD = "h3"
}: AuthCaptionProps): JSX.Element => {
  const downMD = useDownBreakpoint("md");

  return (
    <Wrapper>
      {topImageVariant && <StyledPlaceholderImage variant={topImageVariant} />}
      <Caption
        variant={downMD ? captionVariantDownMD : captionVariantUpFromMD}
        textAlign="center"
      >
        {caption}
        {captionEmojiImage && (
          <>
            &nbsp;
            <EmojiImage src={captionEmojiImage} />
          </>
        )}
      </Caption>

      <Typography variant="paragraphTertiary" textAlign="center">
        {subCaption}
      </Typography>
    </Wrapper>
  );
};

export default AuthCaption;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
`;

const StyledPlaceholderImage = styled(PlaceholderImage)`
  margin: ${({ theme }) => theme.spacing(0, "auto", 7)};
`;

const Caption = styled(Typography)`
  white-space: nowrap;
`;

const EmojiImage = styled.img`
  height: 28px;
`;
