import styled from "@emotion/styled";

import AuthLayoutStatementCarousel from "./AuthLayoutStatementCarousel";

type Props = {
  children: React.ReactNode;
  backHref?: string;
};

const AuthLayout = ({ children }: Props): JSX.Element => (
  <Wrapper>
    {children}
    <RightSide>
      <AuthLayoutStatementCarousel />
    </RightSide>
  </Wrapper>
);

export default AuthLayout;

const Wrapper = styled.div`
  background: ${({ theme }) => theme.palette.background.octonary};
  display: flex;
  height: 100%;
  min-height: 100vh;
  width: 100%;
`;

const RightSide = styled.div`
  width: 426px;

  ${({ theme }) => theme.breakpoints.down("md")} {
    display: none;
  }
`;
