import styled from "@emotion/styled";
import Button from "@mui/material/Button";

const AuthActionButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing(2)};
`;
AuthActionButton.defaultProps = {
  color: "brandGreen",
  size: "large",
  variant: "contained",
  type: "submit",
  fullWidth: true
};

export default AuthActionButton;
